import { array } from "yup";

export const tableGridBlogAggregator = (blog) => {
  let blogAggregated = null;
  if (blog.properties) {
    for (let i in blog.properties) {
      if (blog.properties[i].name && blog.properties[i].value) {
        blogAggregated = blogAggregated || {};
        blogAggregated[blog.properties[i].name] = blog.properties[i].value;
      }
    }
    blogAggregated.id = blog._id;
  }
  blogAggregated.created = new Date(blog.created.date);
  blogAggregated.updated = new Date(blog.updated.date);
  blogAggregated.slug = blog.slug;
  blogAggregated.author = blog.author;
  blogAggregated.published = blog.published;
  blogAggregated.isStar = blog.isStar;
  return blogAggregated;
};

function aggregatePropertiesFormToBlog(properties) {
  let propertiesAggregated = [];
  for (let key in properties) {
    let listOfValues = [];
    if (properties[key].array instanceof Array) {
      properties[key].array.forEach((val) => {
        listOfValues.push(aggregatePropertiesFormToBlog({ [key]: val })[0]);
      });
    }
    if (key !== "array") {
      propertiesAggregated.push({
        name: key,
        value: properties[key].value,
        listOfValues,
        settings: properties[key].settings
          ? aggregatePropertiesFormToBlog(properties[key].settings)
          : undefined,
      });
    }
  }
  return propertiesAggregated;
}

function aggregateComponentsFormToBlog(components) {
  let componentsAggregated = [];
  for (let key in components) {
    components[key].forEach((component) => {
      if (component) {
        componentsAggregated.push({
          position: component.position,
          name: key,
          properties: aggregatePropertiesFormToBlog(component),
        });
      }
    });
  }

  componentsAggregated.sort((a, b) => {
    return a.position - b.position;
  });
  return componentsAggregated;
}
export const aggregateFormComponentToBlog = (fields) => {
  return {
    slug: fields.slug,
    created: fields.created,
    properties: aggregatePropertiesFormToBlog(fields.properties),
    components: aggregateComponentsFormToBlog(fields.components),
  };
};

function aggregatePropertyBlogToForm(property) {
  const agg = { value: property.value };
  if (property.settings && property.settings.length > 0) {
    agg.settings = aggregatePropertiesBlogToForm(property.settings);
  }
  return agg;
}

function aggregatePropertiesBlogToForm(properties) {
  let propertiesAggregated = {};
  properties.forEach((property) => {
    if (property.listOfValues && property.listOfValues.length > 0) {
      propertiesAggregated[property.name] = {};
      propertiesAggregated[property.name].array = [];
      property.listOfValues.forEach((val) => {
        propertiesAggregated[property.name].array.push(
          aggregatePropertyBlogToForm(val)
        );
      });
    } else {
      propertiesAggregated[property.name] =
        aggregatePropertyBlogToForm(property);
    }
  });

  return propertiesAggregated;
}

function aggregateComponentsBlogToForm(components) {
  let componentsAggregated = {};
  let componentCount = {};
  components.forEach((component) => {
    const name = component.name.split("_")[0];
    componentsAggregated[name] = componentsAggregated[name] || [];
    const agg = aggregatePropertiesBlogToForm(component.properties);
    componentsAggregated[name].push(agg);
    componentsAggregated[name].type = component.name.split("_")[0];
    componentCount[name] =
      (componentCount[name] !== undefined && componentCount[name] + 1) || 0;
    agg.componentIndex = componentCount[name];
  });
  return componentsAggregated;
}

export const aggregateBogToFormComponent = (blog) => {
  return {
    slug: blog.slug,
    created: blog.created,
    properties: aggregatePropertiesBlogToForm(blog.properties),
    components: aggregateComponentsBlogToForm(blog.components),
  };
};
