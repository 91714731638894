import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { AddCircleTwoTone, RemoveCircleTwoTone } from "@mui/icons-material";
import { red } from "@mui/material/colors";

export default function CMSAddAndRemoveComponent({
  component,
  components,
  index,
  componentCount,
  setComponentCount,
  setComponents,
  formik,
}) {
  function handleAddComponent(e) {
    e.stopPropagation();
    let position =
      e.currentTarget.dataset.index && parseInt(e.currentTarget.dataset.index);
    if (position !== undefined && components[position]) {
      const componentClone = JSON.parse(JSON.stringify(components[position]));
      const index = (componentCount[componentClone.type] || 1) + 1;
      setComponentCount((prevState) => {
        return {
          ...prevState,
          [componentClone.type]: index,
        };
      });
      componentClone.componentIndex = index;
      setComponents((prevState) => {
        prevState.splice(position + 1, 0, componentClone);
        return prevState;
      });
    }
  }

  function handleRemoveComponent(e) {
    e.stopPropagation();
    const index =
      e.currentTarget.dataset.index && parseInt(e.currentTarget.dataset.index);
    if (index !== undefined && components[index]) {
      const component = components[index];
      function setComponentsData() {
        let newComponents = [];
        components.forEach((data, i) => {
          if (i !== index) {
            if (
              data.name === component.name &&
              data.componentIndex > component.componentIndex
            ) {
              data.componentIndex--;
            }
            newComponents.push(data);
          }
        });
        setComponentCount((prevState) => {
          return {
            ...prevState,
            [component.type]: prevState[component.type] - 1,
          };
        });
        setComponents((prevState) => {
          return newComponents;
        });
      }

      if (
        formik.values &&
        formik.values.components &&
        formik.values.components[component.name]
      ) {
        formik.values.components[component.name].splice(
          component.componentIndex,
          1
        );
        formik
          .setFieldValue(
            "components." + component.name,
            formik.values.components[component.name]
          )
          .then(() => {
            setComponentsData();
          })
          .catch((e) => {
            setComponentsData();
          });
      } else {
        setComponentsData();
      }
    }
  }

  if (!component.mandatory && !component.disabled) {
    return (
      <Grid
        item
        key={`${component.name}${component.componentIndex}AddCompo`}
        sx={{ width: "80px" }}
      >
        <Tooltip title="Ajouter un composant de ce type">
          <IconButton
            key={component.name + component.componentIndex + "AddCompo"}
            name={component.name + component.componentIndex + "AddCompo"}
            data-index={index}
            onClick={handleAddComponent}
          >
            <AddCircleTwoTone color="primary" />
          </IconButton>
        </Tooltip>
        {(componentCount[component.type] > 1 && (
          <Tooltip title="Supprimer ce composant">
            <IconButton
              key={component.name + component.componentIndex + "RmCompo"}
              name={component.name + component.componentIndex + "RmCompo"}
              data-index={index}
              onClick={handleRemoveComponent}
            >
              <RemoveCircleTwoTone sx={{ color: red[700] }} />
            </IconButton>
          </Tooltip>
        )) || (
          <IconButton disabled>
            <RemoveCircleTwoTone />
          </IconButton>
        )}
      </Grid>
    );
  } else {
    return (
      <Grid
        item
        key={`${component.name}${component.componentIndex}AddCompo`}
        sx={{ width: "80px" }}
      >
        {" "}
      </Grid>
    );
  }
}
