import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import CMSTextarea from "./CMSTextarea";
import CMSUploadOrLinkImage from "./CMSUploadOrLinkImage";
import CMSListOfValuesAndUploadImages from "./CMSListOfValuesAndUploadImages";
import CMSUploadOrLinkVideo from "./CMSUploadOrLinkVideo";
import CMSAutoCompleteCategory from "./CMSAutoCompleteCategory";
import CMSText from "./CMSText";
import * as React from "react";
import CMSSlug from "./CMSSlug";
import CMSForceCreationDateProperties from "./CMSForceCreationDate";

export default function CMSFormProperties({
  component,
  formik,
  forceCreationDate,
  setForceCreationDate,
}) {
  return (
    <Box key={`${component.name}Form`}>
      {" "}
      {component.isContainer && <CMSSlug key={"CMSSlug"} formik={formik} />}
      {component.isContainer && (
        <CMSForceCreationDateProperties
          key={"CMSForceCreationDateProperties"}
          formik={formik}
          forceCreationDate={forceCreationDate}
          setForceCreationDate={setForceCreationDate}
        />
      )}
      {!component.disabled &&
        component.properties.map((property, index) => {
          const inpuType = property.settings.find((data) => {
            return data.name === "inputType";
          }).value;
          let cmsParams = { component, formik, property };
          switch (inpuType) {
            case "textarea":
              return (
                <CMSTextarea
                  key={`CMSTextarea${component.name}${index}`}
                  {...cmsParams}
                />
              );
            case "uploadOrLinkImage":
              return (
                <CMSUploadOrLinkImage
                  key={`CMSUploadOrLinkImage${component.name}${index}`}
                  {...cmsParams}
                />
              );
            case "listOfValuesAndUploadImages":
              return (
                <CMSListOfValuesAndUploadImages
                  key={`CMSListOfValuesAndUploadImages${component.name}${index}`}
                  {...cmsParams}
                />
              );
            case "uploadOrLinkVideo":
              return (
                <CMSUploadOrLinkVideo
                  key={`CMSUploadOrLinkVideo${component.name}${index}`}
                  {...cmsParams}
                />
              );
            case "autoCompleteCategory":
              return (
                <CMSAutoCompleteCategory
                  key={`CMSAutoCompleteCategory${component.name}${index}`}
                  {...cmsParams}
                />
              );
            default:
              return (
                <CMSText
                  key={`CMSText${component.name}${index}`}
                  {...cmsParams}
                />
              );
          }
        })}
    </Box>
  );
}
