import Grid from "@mui/material/Grid";
import CMSAddAndRemoveComponent from "./CMSAddAndRemoveComponent";
import CMSMoveUpAndDownComponent from "./CMSMoveUpAndDownComponent";
import CMSEnableDisableComponent from "./CMSEnableDisableComponent";

export default function CMSComponentsToolbar(cmsParams) {
  return (
    !cmsParams.component.isContainer && (
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "265px",
          marginLeft: "auto",
          borderRadius: "16px",
          border: 1,
          boxShadow: 2,
          backgroundColor: "#f2f2f2",
        }}
      >
        <CMSAddAndRemoveComponent {...cmsParams} />
        <CMSMoveUpAndDownComponent {...cmsParams} />
        <CMSEnableDisableComponent {...cmsParams} />
      </Grid>
    )
  );
}
