import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import * as React from "react";
import { arrayMove } from "../../../utils/misc";

export default function CMSMoveUpAndDownComponent({
  component,
  components,
  index,
  setComponents,
}) {
  function handleUpComponent(e) {
    e.stopPropagation();
    const index =
      e.currentTarget.dataset.index && parseInt(e.currentTarget.dataset.index);
    if (index - 1 > 0) {
      const newComponents = arrayMove(components, index, index - 1);
      setComponents(() => {
        return newComponents;
      });
    }
  }

  function handleDownComponent(e) {
    e.stopPropagation();
    const index =
      e.currentTarget.dataset.index && parseInt(e.currentTarget.dataset.index);
    if (index + 1 < components.length) {
      const newComponents = arrayMove(components, index + 1, index);
      setComponents(() => {
        return newComponents;
      });
    }
  }

  if (!component.mandatory && !component.disabled) {
    return (
      <Grid item sx={{ width: "80px" }}>
        {(index < components.length - 1 && (
          <Tooltip title="Déplacer ce composant vers le bas">
            <IconButton
              key={
                component.name + component.componentIndex + "KeyboardArrowDown"
              }
              name={
                component.name + component.componentIndex + "KeyboardArrowDown"
              }
              data-index={index}
              onClick={handleDownComponent}
            >
              <ArrowDownward fontSize="inherit" color="primary" />
            </IconButton>
          </Tooltip>
        )) || (
          <IconButton data-index={index} disabled>
            <ArrowDownward fontSize="inherit" />
          </IconButton>
        )}
        {(index > 1 && (
          <Tooltip title="Déplacer ce composant vers le haut">
            <IconButton
              key={
                component.name + component.componentIndex + "KeyboardArrowUp"
              }
              name={
                component.name + component.componentIndex + "KeyboardArrowUp"
              }
              data-index={index}
              onClick={handleUpComponent}
            >
              <ArrowUpward fontSize="inherit" color="primary" />
            </IconButton>
          </Tooltip>
        )) || (
          <IconButton data-index={index} disabled>
            <ArrowUpward fontSize="inherit" />
          </IconButton>
        )}
      </Grid>
    );
  } else {
    return (
      <Grid item sx={{ width: "80px" }}>
        {" "}
      </Grid>
    );
  }
}
