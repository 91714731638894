import React from 'react';
import { Route, Routes } from 'react-router-dom';

import List  from './List';
import AddEdit from './AddEdit';

export default function Users() {
    return (
        <Routes>
            <Route exact path="/" element=<List /> />
            <Route path="/add" element=<AddEdit /> />
            <Route path="/edit/:id" element=<AddEdit />   />
        </Routes>
    );
}