import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { UserContext } from "../../contexts/UserContext";
import { Logout, Warning } from "@mui/icons-material";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useContext } from "react";
import { FromLocalStorageContext } from "../../contexts/FromLocalStorage";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const lightColor = "rgba(255, 255, 255, 0.7)";

function Header(props) {
  const { onDrawerToggle } = props;
  const { loadedLocally } = useContext(FromLocalStorageContext);

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name: string) {
    const names = name.split(" ");
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${names[0][0]}${(names[1] && names[1][0]) || ""}`,
    };
  }

  return (
    <UserContext.Consumer>
      {({ user, setUser, logoutUser }) => (
        <React.Fragment>
          {loadedLocally && (
            <AppBar color="primary" position="sticky" elevation={0}>
              <Toolbar>
                <Grid
                  container
                  alignItems="center"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Alert severity="info">
                    Attention, contenu chargé localement suite à
                    l'enregistrement (Appuyez sur reset pour effacer)
                  </Alert>
                </Grid>
              </Toolbar>
            </AppBar>
          )}
          <AppBar color="primary" position="sticky" elevation={0}>
            <Toolbar>
              <Grid container spacing={1} alignItems="center">
                <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onDrawerToggle}
                    edge="start"
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid item xs />
                <Grid item></Grid>
                <Grid item>
                  <Tooltip title={user.name}>
                    <Link to={"users/edit/" + user._id}>
                      <IconButton color="inherit" sx={{ p: 0.5 }}>
                        <Avatar {...stringAvatar(user.name || "")} />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <AppBar
            component="div"
            color="primary"
            position="static"
            elevation={0}
            sx={{ zIndex: 0 }}
          >
            <Toolbar>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs>
                  <Breadcrumbs />
                </Grid>
                <Grid item>
                  <Button
                    sx={{ borderColor: lightColor }}
                    variant="outlined"
                    color="inherit"
                    size="small"
                    onClick={logoutUser}
                  >
                    <Logout />
                    Se déconnecter
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </React.Fragment>
      )}
    </UserContext.Consumer>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
