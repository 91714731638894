import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { Switch } from "@mui/material";

export default function CMSEnableDisableComponent({
  component,
  components,
  index,
  componentCount,
  setComponentCount,
  setComponents,
  formik,
}) {
  function enableDisableComponent(e) {
    e.stopPropagation();
    const target = e.currentTarget || e.target;
    const index = target.dataset.index;
    if (index) {
      const component = components[index];
      if (!components[index].disabled) {
        formik.setFieldValue("components." + component.name, []).then(() => {
          setComponents((prevState) => {
            const newState = [];
            prevState[index].disabled = !prevState[index].disabled;
            prevState.forEach((component) => {
              newState.push(component);
            });
            return newState;
          });
        });
      } else {
        setComponentCount((prevState) => {
          return { ...prevState, [component.name]: 0 };
        });
        setComponents((prevState) => {
          const newState = [];
          prevState[index].disabled = !prevState[index].disabled;
          prevState.forEach((component) => {
            newState.push(component);
          });
          return newState;
        });
      }
    }
  }
  if (
    !component.mandatory &&
    (componentCount[component.type] === 1 || !componentCount[component.type])
  ) {
    return (
      <Grid
        item
        key={`${component.name}${component.componentIndex}EnablerGrid`}
        sx={{
          width: "70px",
          display: "flex",
          height: "42px",
          justifyContent: "center",
          alignSelf: "end",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Tooltip title={component.disabled ? "Activer" : "Désactiver"}>
          <Switch
            size="small"
            key={`${component.name}${component.componentIndex}Enabler`}
            id={`${component.name}${component.componentIndex}Enabler`}
            name={`${component.name}${component.componentIndex}Enabler`}
            data-index={index}
            checked={!component.disabled}
            onClick={enableDisableComponent}
            color="primary"
          />
        </Tooltip>
      </Grid>
    );
  } else {
    return (
      <Grid
        item
        key={`${component.name}${component.componentIndex}EnablerGrid`}
        sx={{
          width: "70px",
          display: "flex",
          height: "42px",
          justifyContent: "center",
          alignSelf: "end",
          flexDirection: "column",
          alignItems: "center",
        }}
      ></Grid>
    );
  }
}
