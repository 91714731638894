import { defaultData, idKey, retrieveTreeValue } from "../../../utils/misc";
import { Box, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import ImageChooseAndUploadDialog from "../../dialogs/ImageChooseAndUploadDialog";

export default function CMSUploadOrLinkImage({
  component,
  property,
  index,
  formik,
}) {
  const [selectedData, setSelectedData] = useState({});
  const [currentField, setCurrentField] = useState({});
  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  let { name, required, label, value } = defaultData(
    component,
    property,
    index,
    formik
  );

  const handleDialogConfirm = (result) => {
    const target = currentField.currentTarget || currentField.target;
    formik.setFieldValue(target.name, result);
  };

  const openImageDialog = (e) => {
    e.preventDefault();
    const target = e.currentTarget || e.target;
    setSelectedData(target.value);
    setCurrentField((prevState) => {
      return e;
    });
    setImageDialogOpen(true);
  };

  label += " (Cliquez dans le champ pour selectionner une image)";
  const altName =
    (!component.isContainer &&
      "components." +
        component.name +
        "." +
        component.componentIndex +
        "." +
        property.name +
        idKey(index) +
        ".settings.alt.value") ||
    "properties." + property.name + idKey(index) + ".settings.alt.value";
  const altValue = retrieveTreeValue(altName, formik.values) || "";
  const altLabel = "Entrez le texte alternatif pour cette image";
  return (
    <Box sx={{ m: 1 }} key={`uploadOrLinkImage${name}`}>
      <ImageChooseAndUploadDialog
        key={`uploadOrLinkImage${name}Dialog`}
        open={imageDialogOpen}
        setOpen={setImageDialogOpen}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        handleDialogConfirm={handleDialogConfirm}
      />
      <TextField
        key={`${name}TextField`}
        onFocus={(e) => {
          e.currentTarget.blur();
          openImageDialog(e);
        }}
        data-name={name}
        required={required}
        id={name}
        name={name}
        label={label}
        fullWidth
        value={value}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        variant="standard"
      />
      {value && (
        <Box key={`${altName}Box`}>
          <img className={"preview"} src={value} />
          <TextField
            key={`${altName}TextField`}
            required={required}
            id={altName}
            name={altName}
            label={altLabel}
            onChange={formik.handleChange}
            value={altValue}
            fullWidth
            variant="standard"
          />
        </Box>
      )}
    </Box>
  );
}
