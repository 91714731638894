import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

export default function BreadcrumbsComponent() {
  const location = useLocation();
  let crumbs = location.pathname.split("/");
  let previous = "";
  const breadcrumbs = crumbs.map((crumb, index) => {
    if (!crumb) {
      return;
    }
    if (index === crumbs.length - 1) {
      return (
        <Typography key={`${index}Crumb`} color="white">
          {crumb}
        </Typography>
      );
    }
    return (
      <Link
        component={RouterLink}
        underline="hover"
        key={`${index}Crumb`}
        color="white"
        to={`${previous}/${crumb}`}
      >
        {crumb}
      </Link>
    );
  });

  return (
    <Stack spacing={2}>
      <Breadcrumbs separator="/" color="white" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
