import { defaultData } from "../../../utils/misc";
import { Box, CardMedia, TextField } from "@mui/material";
import VideoChooseAndUploadDialog from "../../dialogs/VideoChooseAndUploadDialog";
import { useState } from "react";

export default function CMSUploadOrLinkVideo({
  component,
  property,
  index,
  formik,
}) {
  let { name, required, label, value } = defaultData(
    component,
    property,
    index,
    formik
  );
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [currentField, setCurrentField] = useState({});
  const handleDialogConfirm = (result) => {
    const target = currentField.currentTarget || currentField.target;
    formik.setFieldValue(target.name, result);
  };
  const openVideoDialog = (e) => {
    e.preventDefault();
    const target = e.currentTarget || e.target;
    setSelectedData(target.value);
    setCurrentField((prevState) => {
      return e;
    });
    setVideoDialogOpen(true);
  };
  label += " (Cliquez dans le champ pour selectionner une image)";
  return (
    <Box sx={{ m: 1 }} key={`uploadOrLinkVideo${name}`}>
      <VideoChooseAndUploadDialog
        key={`uploadOrLinkVideoDialog${name}`}
        open={videoDialogOpen}
        setOpen={setVideoDialogOpen}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        handleDialogConfirm={handleDialogConfirm}
      />
      <TextField
        key={`${name}TextField`}
        data-name={name}
        data-multiple={false}
        required={required}
        id={name}
        name={name}
        label={label}
        fullWidth
        onFocus={(e) => {
          e.currentTarget.blur();
          openVideoDialog(e);
        }}
        value={value}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        variant="standard"
      />
      {value && (
        <CardMedia
          component="video"
          key={`${name}VideoChoosed`}
          loading="lazy"
          controls
          src={value}
        />
      )}
    </Box>
  );
}
