import * as React from "react";
import { retrieveTreeValue } from "../../../utils/misc";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { Delete, ExpandMore, Save, Send } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import CMSFormProperties from "../Inputs/CMSFormProperties";
import { useEffect, useState } from "react";
import CMSFormButtons from "./CMSFormButtons";
import CMSComponentsToolbar from "../ComponentsManagement/CMSComponentsToolbar";

export default function CMSFormComponents({
  setComponents,
  componentCount,
  setComponentCount,
  forceCreationDate,
  setForceCreationDate,
  components,
  loadData,
  formik,
}) {
  const [allAccordionOpen, setAllAccordionOpen] = useState(false);
  const [expanded, setExpanded] = useState(
    components.length > 0 &&
      `panel${components[0].name}${components[0].componentIndex}`
  );

  const handleChangePanel =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setAllAccordionOpen(false);
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <form onSubmit={formik.handleSubmit}>
      {components.map((component, index) => {
        let componentTitle;
        if (!component.isContainer) {
          let name =
            "components." +
            component.name +
            "." +
            component.componentIndex +
            ".title.value";
          componentTitle = retrieveTreeValue(name, formik.values) || "";
        }
        let accordionTitle =
          component.h1 + (componentTitle ? `: ${componentTitle}` : "");
        if (accordionTitle.length > 87) {
          accordionTitle = accordionTitle.substring(0, 84) + "...";
        }

        const cmsParams = {
          component,
          components,
          index,
          componentCount,
          setComponentCount,
          setComponents,
          formik,
        };
        return (
          <Accordion
            key={`${component.name}${component.componentIndex}Accordion`}
            expanded={
              expanded ===
                `panel${component.name}${component.componentIndex}` ||
              allAccordionOpen
            }
            onChange={handleChangePanel(
              `panel${component.name}${component.componentIndex}`
            )}
          >
            <AccordionSummary
              key={`${component.name}${component.componentIndex}AccordionSummary`}
              expandIcon={<ExpandMore />}
              aria-controls={`panel${component.name}${component.componentIndex}`}
              id={`panelHeader${component.name}${component.componentIndex}`}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography title={componentTitle}>
                    {accordionTitle}
                  </Typography>
                </Grid>
                <CMSComponentsToolbar {...cmsParams} />
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <CMSFormProperties
                component={component}
                formik={formik}
                forceCreationDate={forceCreationDate}
                setForceCreationDate={setForceCreationDate}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
      <CMSFormButtons
        {...{
          componentCount,
          components,
          formik,
          loadData,
          setAllAccordionOpen,
        }}
      />
    </form>
  );
}
