import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { Delete, Save, Send } from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import DeleteDialog from "../../dialogs/DeleteDialog";

export default function CMSFormButtons({
  componentCount,
  components,
  setAllAccordionOpen,
  formik,
  loadData,
}) {
  const { id } = useParams();
  const isAddMode = !id;
  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  function handleResetContext() {
    if (isAddMode) {
      localStorage.removeItem("addBlog");
      formik.resetForm();
      loadData();
    } else {
      localStorage.removeItem("editBlog:" + id);
      loadData();
    }
  }

  const openResetDialog = (e) => {
    e.preventDefault();
    setResetDialogOpen(true);
  };
  function saveContext() {
    const state = {
      componentCount: componentCount,
      components: components,
      formikValues: formik.values,
    };
    if (isAddMode) {
      localStorage.setItem("addBlog", JSON.stringify(state));
    } else {
      localStorage.setItem("editBlog:" + id, JSON.stringify(state));
    }
  }
  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
    >
      <DeleteDialog
        handleDelete={handleResetContext}
        open={resetDialogOpen}
        setOpen={setResetDialogOpen}
        msge={
          "Voulez-vous vraiment supprimer l'enregistrement local pour cet element?"
        }
      />
      <Toolbar>
        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
          }}
        >
          <Button
            variant="outlined"
            color="success"
            onClick={saveContext}
            endIcon={<Save />}
          >
            Enregistrer
          </Button>
          <Button
            variant="outlined"
            color="error"
            onFocus={openResetDialog}
            endIcon={<Delete />}
          >
            Reset
          </Button>
          <Button
            sx={{
              justifyContent: "flex-end",
              alignSelf: "end",
              marginLeft: "auto",
            }}
            variant="contained"
            type="submit"
            onClick={() => {
              setAllAccordionOpen(true);
            }}
            endIcon={<Send />}
          >
            {isAddMode ? "Ajouter " : "Modifier "}
          </Button>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
