import { Box, TextField } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as React from "react";
import { defaultData } from "../../../utils/misc";

export default function CMSTextarea({ component, property, formik }) {
  const selectNextInputCKeditor = (e) => {
    const input = document
      .getElementById(`${e.currentTarget.name}.container`)
      .querySelector(`.ck-content`);
    window.getSelection().selectAllChildren(input);
    window.getSelection().collapseToEnd();
  };

  let { name, value, label, required, touched, error } = defaultData(
    component,
    property,
    undefined,
    formik
  );
  return (
    <Box sx={{ m: 1 }} id={`${name}.container`} key={`Textarea${name}`}>
      <TextField
        key={`${name}TextField`}
        label={label}
        required={required}
        id={name}
        name={name}
        onFocus={selectNextInputCKeditor}
        name={name}
        fullWidth
        value={value}
        error={touched && Boolean(error)}
        variant="standard"
      />
      <CKEditor
        key={`${name}Editor`}
        id={`${name}Editor`}
        name={`${name}Editor`}
        editor={ClassicEditor}
        data={value}
        error={touched && Boolean(error)}
        config={{
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
          ],
        }}
        onBlur={(event, editor) => {
          const data = editor
            .getData()
            .replaceAll("href", ' target="_blank" href');
          formik.setFieldValue(name, data);
        }}
      />{" "}
    </Box>
  );
}
