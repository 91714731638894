const handleOnKeyDownEnter = (e) => {
  if (
    e.key === "Enter" &&
    e.currentTarget.type !== "button" &&
    e.currentTarget.type !== "submit" &&
    e.currentTarget.type !== "textarea"
  ) {
    e.preventDefault();

    return false;
  }
};

const retrieveTreeValue = (path, object) => {
  let value;
  path.split(".").forEach((subPath, i) => {
    if (i === 0) {
      value = object[subPath];
    } else if (value !== undefined) {
      value = value[subPath];
    }
  });
  return value;
};

function arrayMove(arr, oldIndex, newIndex) {
  const newArray = [];
  arr.forEach((val, index) => {
    if (index === newIndex) {
      newArray.push(arr[oldIndex]);
    }
    if (index !== oldIndex) {
      newArray.push(val);
    }
  });
  return newArray;
}

function idKey(index) {
  return index !== undefined ? ".array." + index : "";
}

function defaultData(component, property, index, formik) {
  let required = property.required;
  let name =
    (!component.isContainer &&
      "components." +
        component.name +
        "." +
        component.componentIndex +
        "." +
        property.name +
        idKey(index) +
        ".value") ||
    "properties." + property.name + idKey(index) + ".value";
  let labelProperty = property.settings.find((data) => {
    return data.name === "label";
  });
  let label = (labelProperty && labelProperty.value) || property.name;
  let value = retrieveTreeValue(name, formik.values) || "";
  let error = retrieveTreeValue(name, formik.errors);
  let touched = retrieveTreeValue(name, formik.touched);
  return { name, required, label, value, error, touched };
}

export {
  handleOnKeyDownEnter,
  retrieveTreeValue,
  arrayMove,
  defaultData,
  idKey,
};
