import { defaultData } from "../../../utils/misc";
import { Box, TextField } from "@mui/material";

export default function CMSText({ component, property, formik }) {
  let { name, required, label, value, error, touched } = defaultData(
    component,
    property,
    undefined,
    formik
  );
  return (
    <Box sx={{ m: 1 }} key={`Text${name}`}>
      {" "}
      <TextField
        key={`${name}TextField`}
        required={required}
        id={name}
        name={name}
        label={label}
        fullWidth
        variant="standard"
        value={value}
        onChange={formik.handleChange}
        error={touched && Boolean(error)}
      />
    </Box>
  );
}
