import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import List from "./List";
import AddEdit from "./AddEdit";

export default function Blogs({ type, s3 }) {
  return (
    <Routes>
      <Route exact path="/" element=<List {...{ type, s3 }} /> />
      <Route path="/add" element=<AddEdit {...{ type, s3 }} /> />
      <Route path="/edit/:id" element=<AddEdit {...{ type, s3 }} /> />
    </Routes>
  );
}
