import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../services/AxiosService";
import { endpoint } from "../../utils/API";
import { toast } from "react-toastify";
import Paper from "@mui/material/Paper";
import {
  Button,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Visibility, VisibilityOff, Send } from "@mui/icons-material";

export default function AddEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isAddMode = !id;
  const [user, setUser] = useState();
  const [initialValues, setInitialValues] = useState({});

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Email is invalid").required("Email requis"),
    role: Yup.string().required("Role is required"),
    password: Yup.string()
      .concat(isAddMode ? Yup.string().required("Mots de passe requis") : null)
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .when("password", (password, schema) => {
        if (password[0] || isAddMode)
          return schema.required("Confirm Password is required");
      })
      .oneOf([Yup.ref("password")], "Les mot de passe ne sont pas identiques"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!isAddMode) {
      // get user and set form fields
      axiosInstance(`${endpoint}/user/${id}`).then((response) => {
        const user = response.data;
        user.password = "";
        user.confirmPassword = "";
        setInitialValues(user);
        setUser(user);
      });
    }
  }, []);
  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    let user = { ...fields };
    delete user.confirmPassword;
    if (isAddMode) {
      createUser(user, setSubmitting);
    } else {
      updateUser(id, user, setSubmitting);
    }
  }

  function createUser(fields, setSubmitting) {
    axiosInstance
      .post(`${endpoint}/user`, fields)
      .then(() => {
        toast.success("User added");
        navigate("../");
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(
          "Une erreur est survenue lors de la création: (status:" +
            error.response.status +
            ") => " +
            error.response.data
        );
      });
  }

  function updateUser(id, fields, setSubmitting) {
    axiosInstance
      .put(`${endpoint}/user/${id}`, fields)
      .then(() => {
        toast.success("User updated");
        navigate("../");
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(
          "Une erreur est survenue lors de la suppression: (status:" +
            error.response.status +
            ") => " +
            error.response.data
        );
      });
  }

  return (
    (!isAddMode && !user && <Paper>Loading..</Paper>) ||
    (((!isAddMode && user) || isAddMode) && (
      <Paper>
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ m: 1, width: "50ch" }} variant="standard">
            <Typography variant="h6" gutterBottom>
              {(isAddMode ? "Ajouter " : "Modifier ") + "un utilisateur"}
            </Typography>
          </FormControl>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ m: 1, width: "50ch" }} variant="standard">
                <TextField
                  required
                  id="name"
                  name="name"
                  label="Nom"
                  fullWidth
                  autoComplete="given-name"
                  variant="standard"
                  value={formik.values.name || ""}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ m: 1, width: "50ch" }} variant="standard">
                <TextField
                  required
                  id="email"
                  name="email"
                  label="email"
                  fullWidth
                  autoComplete="email"
                  variant="standard"
                  value={formik.values.email || ""}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "50ch" }} variant="standard">
                <TextField
                  required
                  select
                  name="role"
                  id="role"
                  label="Rôle"
                  defaultValue="EDITOR"
                  variant="standard"
                  value={formik.values.role || ""}
                  onChange={formik.handleChange}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                >
                  <MenuItem key="EDITOR" value="EDITOR">
                    EDITOR
                  </MenuItem>
                  <MenuItem key="ADMIN" value="ADMIN">
                    ADMIN
                  </MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ m: 1, width: "50ch" }} variant="standard">
                <TextField
                  id="standard-adornment-password"
                  name="password"
                  label="Mot de passe"
                  type={"password"}
                  value={formik.values.password || ""}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  variant="standard"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ m: 1, width: "50ch" }} variant="standard">
                <TextField
                  id="confirmPassword"
                  label="Confirmation du mot de passe"
                  type="password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword || ""}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  variant="standard"
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                m: 1,
                width: "25ch",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 3, ml: 1 }}
                endIcon={<Send />}
              >
                {isAddMode ? "Ajouter " : "Modifier "}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    )) || <Paper> User not found </Paper>
  );
}
