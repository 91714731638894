import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import axiosInstance from "../../services/AxiosService";
import { endpoint } from "../../utils/API";
import { Box, LinearProgress } from "@mui/material";
import { DataGrid, frFR } from "@mui/x-data-grid";
import {
  StarOutline,
  StarTwoTone,
  VisibilityTwoTone,
  VisibilityOffTwoTone,
  DeleteTwoTone,
  EditTwoTone,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { tableGridBlogAggregator } from "../../aggregators/BlogAggregator";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import { red, yellow } from "@mui/material/colors";

export default function List({ type }) {
  const [blogs, setBlogs] = React.useState([]);
  const [dataBlogs, setDataBlogs] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [deleteBlogId, setDeleteBlogId] = React.useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const openDeleteDialog = (e) => {
    e.preventDefault();

    const id = e.currentTarget.dataset.id;

    setDeleteBlogId(id);
    setDeleteDialogOpen(true);
  };

  const publishBlog = (e) => {
    const target = e.currentTarget || e.target;
    axiosInstance
      .put(`${endpoint}/publish/${target.dataset.id}?type=${type}`)
      .then(() => {
        toast.success("Blog published");
        loadBlogsData();
      })
      .catch((e) => {
        toast.error("Error on blog published");
      });
  };

  const starUnstar = (e) => {
    const target = e.currentTarget || e.target;
    axiosInstance
      .put(`${endpoint}/star-unstar/${target.dataset.id}`)
      .then((result) => {
        toast.success(result.data.msge);
        loadBlogsData();
      })
      .catch((e) => {
        toast.error("Error on blog published");
      });
  };

  const unpublishBlog = (e) => {
    const target = e.currentTarget || e.target;
    axiosInstance
      .delete(`${endpoint}/publish/${target.dataset.slug}?type=${type}`)
      .then(() => {
        toast.success("Blog unpublished");
        loadBlogsData();
      })
      .catch((e) => {
        toast.error("Error on blog unpublished");
      });
  };

  const columns = [
    {
      field: "title",
      headerName: "Titre",
      flex: 0.38,
      minWidth: 400,
    },
    {
      field: "author",
      headerName: "Auteur",
      flex: 0.08,
      minWidth: 80,
    },
    {
      field: "created",
      headerName: "Date de création",
      flex: 0.19,
      minWidth: 200,
      valueFormatter: (params) =>
        dayjs(params.value).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "updated",
      headerName: "Date de mise à jour",
      flex: 0.19,
      minWidth: 200,
      valueFormatter: (params) =>
        dayjs(params.value).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      width: 180,
      minWidth: 180,
      hideable: false,
      renderCell: (params) => {
        return (
          <>
            {(params.row.isStar && (
              <Tooltip title={"Défaire cette article star"}>
                <IconButton
                  className="unstaring"
                  alt="Cliquez ici pour défaire cet article star"
                  data-id={params.row.id}
                  onClick={starUnstar}
                >
                  <StarTwoTone sx={{ color: yellow[700] }} fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )) || (
              <Tooltip title={"Faire de cette article un article star"}>
                <IconButton
                  className="makeMeAStar"
                  data-id={params.row.id}
                  alt="Cliquez ici pour mettre le blog en star"
                  onClick={starUnstar}
                >
                  {" "}
                  <StarOutline fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}

            {(params.row.published && (
              <Tooltip title={"Dépublier cette article"}>
                <IconButton
                  className="unpublish"
                  data-params={JSON.stringify(params.row)}
                  alt="Cliquez ici pour dépublier"
                  data-slug={params.row.slug}
                  onClick={unpublishBlog}
                >
                  <VisibilityTwoTone fontSize="inherit" color="primary" />
                </IconButton>
              </Tooltip>
            )) || (
              <Tooltip title={"Publier cet article"}>
                <IconButton
                  className="publish"
                  data-id={params.row.id}
                  alt="Cliquez ici pour publier"
                  onClick={publishBlog}
                >
                  <VisibilityOffTwoTone fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={"Editer cet article"}>
              <Link to={"./edit/" + params.row.id}>
                <IconButton
                  aria-label="blogListEdit"
                  size="small"
                  className="blogListEdit"
                >
                  <EditTwoTone fontSize="inherit" color="primary" />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title={"Supprimer cet article"}>
              <IconButton
                aria-label="delete"
                size="small"
                className="blogList"
                data-id={params.row.id}
                onClick={openDeleteDialog}
              >
                <DeleteTwoTone fontSize="inherit" sx={{ color: red[700] }} />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const loadBlogsData = () => {
    setBlogs([]);
    setDataBlogs([]);
    setLoading(true);
    axiosInstance
      .get(`${endpoint}/blogs?type=${type}`)
      .then((result) => {
        const rows = result.data.map((blog) => {
          return tableGridBlogAggregator(blog);
        });
        const data = { columns: columns, rows };
        setBlogs((prevState) => {
          return result.data;
        });
        setDataBlogs((prevState) => {
          return data;
        });
        setLoading(false);
      })
      .catch((e) => {
        toast.error("Nous ne parvenons pas à retrouver la liste de blogs");
      });
  };

  useEffect(() => {
    loadBlogsData();
  }, [type]);

  const handleDelete = () => {
    axiosInstance
      .delete(`${endpoint}/blog/${deleteBlogId}?type=${type}`)
      .then(() => {
        toast.success("Blog supprimé");
        loadBlogsData();
      })
      .catch((error) => {
        toast.error(
          "Une erreur est survenue lors de la suppression: (status:" +
            error.response.status +
            ") => " +
            error.response.data
        );
      });
  };

  return (
    <Paper>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item></Grid>
            <Grid item xs></Grid>
            <Grid item>
              <Button
                onClick={() => {
                  navigate("./add");
                }}
                variant="contained"
                sx={{ mr: 1 }}
              >
                Créer un nouveau blog
              </Button>
              <Tooltip title="Reload">
                <IconButton onClick={loadBlogsData}>
                  <RefreshIcon color="inherit" sx={{ display: "block" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {(loading && (
        <>
          <Typography
            sx={{ my: 5, mx: 2 }}
            color="text.secondary"
            align="center"
          >
            Chargement des données
          </Typography>
          <LinearProgress />
        </>
      )) ||
        (blogs && blogs.length == 0 && (
          <Typography
            sx={{ my: 5, mx: 2 }}
            color="text.secondary"
            align="center"
          >
            Aucun elements trouvé
          </Typography>
        )) ||
        (dataBlogs && (
          <Grid item sx={{ height: 520, width: "100%" }}>
            <DataGrid
              sx={{ border: "none", width: "100%" }}
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              {...dataBlogs}
              loading={blogs === undefined}
              rowHeight={38}
              disableSelectionOnClick
            />
          </Grid>
        ))}
      <DeleteDialog
        handleDelete={handleDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        msge={"Voulez-vous vraiment supprimer ce blog?"}
      />
    </Paper>
  );
}
