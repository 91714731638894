import { defaultData } from "../../../utils/misc";
import { Autocomplete, Box, TextField } from "@mui/material";
import axiosInstance from "../../../services/AxiosService";
import { endpoint } from "../../../utils/API";
import { useEffect, useState } from "react";

export default function CMSAutoCompleteCategory({
  component,
  property,
  index,
  formik,
}) {
  let { name, required, label, value } = defaultData(
    component,
    property,
    index,
    formik
  );
  const [categories, setCategories] = useState([]);

  const loadData = () => {
    axiosInstance(`${endpoint}/categories`)
      .then((responseAxios) => {
        setCategories(() => {
          return responseAxios.data.map((value) => {
            return { value, label: value };
          });
        });
      })
      .catch((e) => {
        console.error("could not retrieve list of categories");
      });
  };
  useEffect(() => {
    loadData();
  }, []);
  if (categories) {
    return (
      <Box sx={{ m: 1 }} key={`autoCompleteCategoryBox${name}`}>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            formik.setFieldValue(name, (newValue && newValue.value) || "");
          }}
          filterOptions={(options, params) => {
            let filtered = options.filter((val) => {
              return val.value.indexOf(params.inputValue) === 0;
            });

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.value
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                value: inputValue,
                label: `Ajouter ${inputValue}`,
              });
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          variant="standard"
          key={`${name}AC`}
          id={name}
          options={categories}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.label;
          }}
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
          sx={{ width: 300 }}
          freeSolo
          renderInput={(params) => (
            <TextField
              key={`${name}TextField`}
              required={required}
              value={value}
              variant="standard"
              {...params}
              label={label}
            />
          )}
        />
      </Box>
    );
  } else {
  }
}
