import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { useContext, useEffect, useState } from "react";
import { s3Endpoint } from "../../utils/API";
import axiosExternalInstance from "../../services/AxiosExternalService";

import {
  CardActionArea,
  CardMedia,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import VideoUpload from "../uploader/VideoUpload";
import { TypeContext } from "../../contexts/TypeContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VideoChooseAndUploadDialog = ({
  open,
  setOpen,
  handleDialogConfirm,
  selectedData,
  setSelectedData,
}) => {
  const [itemData, setItemData] = useState([]);
  const { s3 } = useContext(TypeContext);

  useEffect(() => {
    if (open) {
      loadVideoList();
    }
  }, [open, s3]);
  useEffect(() => {}, [selectedData]);

  const loadVideoList = (url) => {
    axiosExternalInstance
      .get(`${s3Endpoint}/?list-type=2&prefix=Videos/${s3}`)
      .then((response) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data, "text/xml");
        let contents = xmlDoc.getElementsByTagName("Contents");
        const datas = [];
        Array.prototype.slice.call(contents).forEach((content, i) => {
          if (content.getElementsByTagName("Size")[0].innerHTML > 0) {
            const key = content.getElementsByTagName("Key")[0].innerHTML;
            const src = s3Endpoint + "/" + key;
            datas.push({ src, key });
          }
        });
        setItemData((prevState) => {
          return datas;
        });
        if (url) {
          setSelectedData(() => {
            return url;
          });
        }
      });
  };
  const handleClose = () => {
    setItemData([]);
    setOpen(false);
  };

  const handleConfirmAndClose = () => {
    handleDialogConfirm(selectedData);
    handleClose();
  };

  const handleSelection = (e) => {
    const target = e.currentTarget || e.target;
    setSelectedData(target.value);
  };
  const handleUpload = ({ url }) => {
    loadVideoList(url);
  };

  return (
    <div key={"VideoChooseAndUploadDialogDiv"}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        key={"dialogVideo"}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle key={"VideoChooseAndUploadDialogTitle"}>
          {"Choisissez une video"}
        </DialogTitle>
        <DialogContent key={"VideoChooseAndUploadDialogContent"}>
          <VideoUpload key={"videoUploadCP"} uploaded={handleUpload} />
          {itemData && itemData.length > 0 && (
            <div style={{ display: "flex", gap: "100px" }} key={`DivContainer`}>
              <RadioGroup
                key={"videoRG"}
                id={"videoRG"}
                value={selectedData}
                onChange={handleSelection}
              >
                {itemData.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "20px",
                    }}
                    key={`${item.key}DivContained`}
                  >
                    {" "}
                    <FormControlLabel
                      key={`${item.key}VFC`}
                      value={item.src}
                      label={item.key}
                      control={
                        <Radio
                          id={`${item.key}Radio`}
                          name={`${item.key}Radio`}
                          key={`${item.key}Radio`}
                          value={item.src}
                        />
                      }
                    />
                    <CardActionArea key={`${item.key}CardActionArea`}>
                      <CardMedia
                        component="video"
                        controls
                        src={item.src}
                        name={`${item.key}CM`}
                        id={`${item.key}CM`}
                        key={`${item.key}CM`}
                      />
                    </CardActionArea>
                  </div>
                ))}
              </RadioGroup>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button key={"videoCancel"} onClick={handleClose}>
            Annuler
          </Button>
          <Button key={"videoConfirm"} onClick={handleConfirmAndClose}>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VideoChooseAndUploadDialog;
