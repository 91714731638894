import { ChangeEvent, useContext, useState } from "react";
import axiosInstance from "../../services/AxiosService";
import { endpoint } from "../../utils/API";
import { LinearProgress, TextField } from "@mui/material";
import { AddPhotoAlternateRounded, Upload } from "@mui/icons-material";
import { toast } from "react-toastify";
import { green } from "@mui/material/colors";
import { TypeContext } from "../../contexts/TypeContext";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

function VideoUpload({ uploaded }) {
  const [file, setFile] = useState();
  const [progress, setProgress] = useState();
  const { type } = useContext(TypeContext);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setFile(e.currentTarget.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }

    const config = {
      onUploadProgress: function (progressEvent) {
        setProgress(() => {
          return Math.round((progressEvent.loaded * 100) / progressEvent.total);
        });
      },
      headers: { "content-type": file.type },
    };

    axiosInstance
      .put(`${endpoint}/files/video/${file.name}?type=${type}`, file, config)
      .then((axiosResp) => {
        toast.success("Fichier uploadé!");
        setProgress((prevState) => {
          return undefined;
        });
        setFile((prevState) => {
          return undefined;
        });
        uploaded(axiosResp.data);
      })
      .catch((e) => {
        console.error(e);
        toast.error("Quelque chose s'est mal passé lors de l'upload!");
        setProgress((prevState) => {
          return undefined;
        });
      });
  };

  return (
    <div key={"videoFileDiv"}>
      {(!progress && (
        <div key={"videoFileDivContainer"}>
          <TextField
            key="videoFile"
            type="file"
            variant={"standard"}
            onChange={handleFileChange}
          />
          <Tooltip title={"Uploader sur le s3 d'Amazon"}>
            <IconButton key={"videoFileUpload"} onClick={handleUploadClick}>
              <Upload fontSize={"inherit"} color={"primary"} />
            </IconButton>
          </Tooltip>
        </div>
      )) || (
        <LinearProgress
          key={"videoFileDivProgress"}
          variant="determinate"
          value={progress}
        />
      )}
    </div>
  );
}

export default VideoUpload;
