import { retrieveTreeValue } from "../../../utils/misc";
import { Box, TextField } from "@mui/material";

export default function CMSSlug({ formik }) {
  const name = "slug";
  const value = formik.values.slug || "";
  const label = "slug (identification unique de fin d'url)";
  let error = retrieveTreeValue(name, formik.errors);
  let touched = retrieveTreeValue(name, formik.touched);
  let helperText = formik.touched.slug && formik.errors.slug;

  return (
    <Box sx={{ m: 1 }} key={"slugBog"}>
      {" "}
      <TextField
        key={"slugInput"}
        required
        id={name}
        name={name}
        label={label}
        fullWidth
        variant="standard"
        value={value}
        onChange={formik.handleChange}
        error={touched && Boolean(error)}
        helperText={helperText}
      />
    </Box>
  );
}
