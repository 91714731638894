import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useContext, useEffect, useState } from "react";
import { s3Endpoint } from "../../utils/API";
import axiosExternalInstance from "../../services/AxiosExternalService";
import AppBar from "@mui/material/AppBar";
import ImageUpload from "../uploader/ImageUpload";
import { TypeContext } from "../../contexts/TypeContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageChooseAndUploadDialog = ({
  open,
  setOpen,
  multiple,
  handleDialogConfirm,
  selectedData,
  setSelectedData,
}) => {
  const { s3 } = useContext(TypeContext);
  const [itemData, setItemData] = useState([]);
  const [selectedDataMap, setSelectedDataMap] = React.useState({});

  const loadImageList = (url) => {
    axiosExternalInstance
      .get(`${s3Endpoint}/?list-type=2&prefix=Images/${s3}`)
      .then((response) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data, "text/xml");
        let contents = xmlDoc.getElementsByTagName("Contents");
        const datas = [];
        const style1 = { rows: 2, cols: 2 };
        const style2 = {
          cols: 2,
        };
        const mapOfData = {};
        Array.prototype.slice.call(contents).forEach((content, i) => {
          if (content.getElementsByTagName("Size")[0].innerHTML > 0) {
            const style =
              (i % 4 == 1 && style1) || (i % 4 == 3 && style2) || {};

            const data =
              s3Endpoint +
              "/" +
              content.getElementsByTagName("Key")[0].innerHTML;
            datas.push({
              img: data,
              ...style,
            });
            mapOfData[data] = true;
          }
        });
        if (multiple) {
          try {
            const datas = JSON.parse(selectedData);
            if (datas instanceof Array) {
              datas.forEach((data) => {
                if (mapOfData[data]) {
                  setSelectedDataMap((prevState) => {
                    return { ...prevState, [data]: true };
                  });
                }
              });
            }
          } catch (e) {
            if (mapOfData[selectedData]) {
              setSelectedDataMap((prevState) => {
                return { ...prevState, [selectedData]: true };
              });
            }
          }
        } else {
          if (url) {
            setSelectedData(() => {
              return url;
            });
          }
          let key = url || selectedData;
          if (mapOfData[key]) {
            setSelectedDataMap((prevState) => {
              return { ...prevState, [key]: true };
            });
          }
        }
        setItemData((prevState) => {
          return datas;
        });
      });
  };
  useEffect(() => {
    if (open) {
      loadImageList();
    }
  }, [open, s3]);
  const handleClose = () => {
    setSelectedDataMap((prevState) => {
      return {};
    });
    setOpen(false);
  };

  const handleComfirmAndClose = () => {
    const result = Object.keys(selectedDataMap);
    handleDialogConfirm(multiple ? result : result[0]);
    setSelectedDataMap((prevState) => {
      return {};
    });
    setOpen(false);
  };

  const handleSelection = (e) => {
    const target = e.currentTarget || e.target;
    setSelectedDataMap((prevState) => {
      if (prevState[target.dataset.name]) {
        delete prevState[target.dataset.name];
      } else if (multiple) {
        prevState[target.dataset.name] = true;
      } else {
        prevState = { [target.dataset.name]: true };
      }
      return { ...prevState };
    });
  };

  const srcset = (image: string, size: number, rows = 1, cols = 1) => {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  };
  const handleUpload = ({ url }) => {
    loadImageList(url);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        key={"imageDialog"}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Choisissez une image"}</DialogTitle>
        <DialogContent>
          <ImageUpload uploaded={handleUpload} />
          <ImageList
            sx={{ width: 500, height: 450 }}
            variant="quilted"
            cols={4}
            rowHeight={121}
          >
            {itemData.map((item) => (
              <ImageListItem
                key={item.img}
                cols={item.cols || 1}
                rows={item.rows || 1}
              >
                <img
                  onClick={handleSelection}
                  className={selectedDataMap[item.img] ? "selected" : ""}
                  data-name={item.img}
                  {...srcset(item.img, 121, item.rows, item.cols)}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleComfirmAndClose}>Confirmer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageChooseAndUploadDialog;
