import axios from "axios";
import { endpoint } from "../utils/API";

const axiosInstance = axios.create({
  baseURL: endpoint,
  headers: {
    "Content-Type": "application/json",
  }
});

export default axiosInstance;
