import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axiosInstance from "../../services/AxiosService";
import { endpoint } from "../../utils/API";
import styles from "./Login.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

const Login = () => {
  const [logo, setLogo] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const from = "" + location.state;
  const { setUser } = useContext(UserContext);
  const [credentials] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (fields) => {
    axiosInstance
      .post(`${endpoint}/auth/login`, fields)
      .then((response) => {
        if (response && response.status === 200) {
          setUser(response.data.user);
          localStorage.setItem("accessToken", response.data.token);

          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.token}`;
        }
      })
      .catch(() => {
        toast.error("Identifiants incorrects!");
      });
  };

  const formik = useFormik({
    initialValues: credentials,
    onSubmit,
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (!logo) {
      import(
        `../../assets/img/${process.env.REACT_APP_LOGO_PATH || ""}logo.png`
      ).then((module) => {
        setLogo(module.default);
      });
    }
    const checkToken = () => {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;

        axiosInstance
          .get(`${endpoint}/user/me`)
          .then((response) => {
            if (response.data) {
              setUser(response.data);
              if (from) {
                navigate(from);
              }
            }
          })
          .catch((e) => {
            toast.error(e);
          });
      }
    };
    checkToken();
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img src={logo} alt="Logo CMS" className={styles.logo} />
      <div className={styles.login}>
        <h1>Connexion</h1>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <TextField
            label="Email"
            variant="outlined"
            name={"email"}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <TextField
            label="Mot de passe"
            variant="outlined"
            name="password"
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            adornmentposition="end"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button type="submit" variant="contained" sx={{ mt: 2, mb: 2 }}>
            Se connecter
          </Button>
        </form>
      </div>
    </Box>
  );
};

export default Login;
