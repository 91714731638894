import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Sidebar from "./views/Sidebar/Sidebar";
import Users from "./views/Users/index";
import Header from "./views/Header/Header";
import { UserContext } from "./contexts/UserContext";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Login from "./pages/login/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Blogs from "./views/Blogs/index";
import { RoutesContext } from "./contexts/RoutesContext";
import { FromLocalStorageContext } from "./contexts/FromLocalStorage";
import { useState } from "react";
import { TypeContext } from "./contexts/TypeContext";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

export default function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const from = window.location.pathname;
  const [user, setUser] = React.useState(null);
  const [routes, setRoutes] = React.useState([]);
  const [typeMap, setTypeMap] = React.useState({});
  const [loadedLocally, setLoadedLocally] = useState(false);

  const logout = () => {
    setUser(null);
    localStorage.removeItem("accessToken");
    toast.success("Déconnexion réussie !");
  };

  const userContext = {
    user,
    setUser,
    logoutUser: logout,
  };

  const routesContext = {
    routes,
    setRoutes,
    typeMap,
    setTypeMap,
  };

  const fromLocalStorageContext = {
    loadedLocally,
    setLoadedLocally,
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const initRoute = () => {
    let targets = [];
    let defaultRoute;

    routes.forEach((route, index) => {
      let element;
      if (route.component === "Users") {
        element = <Users key={`Users${index}`} />;
      } else {
        element = (
          <Blogs key={`Blogs${index}`} type={route.type} s3={route.s3} />
        );
        defaultRoute = defaultRoute || route.path;
      }

      targets.push(
        <Route key={`Route${index}`} path={route.route} element={element} />
      );
    });
    targets.push(
      <Route
        key={"RouteDefault"}
        path="*"
        element={<Navigate to={defaultRoute} state={from} />}
      />
    );
    return targets;
  };
  return (
    <UserContext.Provider value={userContext}>
      <RoutesContext.Provider value={routesContext}>
        <FromLocalStorageContext.Provider value={fromLocalStorageContext}>
          <Router>
            {!user ? (
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="*" element={<Navigate to="/" state={from} />} />
              </Routes>
            ) : (
              <>
                <ThemeProvider theme={theme}>
                  <Box sx={{ display: "flex", minHeight: "100vh" }}>
                    <CssBaseline />
                    <Box
                      component="nav"
                      sx={{
                        width: { sm: drawerWidth },
                        flexShrink: { sm: 0 },
                      }}
                    >
                      {isSmUp ? null : (
                        <Sidebar
                          PaperProps={{ style: { width: drawerWidth } }}
                          variant="temporary"
                          open={mobileOpen}
                          onClose={handleDrawerToggle}
                        />
                      )}

                      <Sidebar
                        PaperProps={{ style: { width: drawerWidth } }}
                        sx={{ display: { sm: "block", xs: "none" } }}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Header onDrawerToggle={handleDrawerToggle} />
                      <Box
                        component="main"
                        sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}
                      >
                        <Routes>{initRoute()}</Routes>
                      </Box>
                      <Box component="footer" sx={{ p: 2, bgcolor: "#eaeff1" }}>
                        <Copyright />
                      </Box>
                    </Box>
                  </Box>
                </ThemeProvider>
              </>
            )}
            <ToastContainer
              position="top-right"
              hideProgressBar={false}
              closeOnClick={true}
              pauseOnFocusLoss={false}
              pauseOnHover={false}
            />
          </Router>
        </FromLocalStorageContext.Provider>
      </RoutesContext.Provider>
    </UserContext.Provider>
  );
}
