import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import axiosInstance from "../../services/AxiosService";
import { endpoint } from "../../utils/API";
import { Box } from "@mui/material";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import { useEffect } from "react";
import { red } from "@mui/material/colors";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";

const columnMap = {
  name: {
    headerName: "Nom",
    flex: 0.11,
  },
  role: {
    headerName: "Rôle",
    flex: 0.09,
  },
  email: {
    headerName: "Email",
    flex: 0.27,
  },
  date: {
    headerName: "Date de création",
    flex: 0.16,
  },
  _id: {
    headerName: "Id",
    flex: 0.27,
  },
};

export default function Users() {
  const navigate = useNavigate();
  const [users, setUsers] = React.useState();
  const [dataUsers, setDataUsers] = React.useState();
  const [deleteUserID, setDeleteUserID] = React.useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const openDeleteDialog = (e) => {
    e.preventDefault();

    const id = e.currentTarget.dataset.id;

    setDeleteUserID(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = () => {
    axiosInstance
      .delete(`${endpoint}/user/${deleteUserID}`)
      .then(() => {
        toast.success("Utilisateur supprimé");
        loadUserData();
      })
      .catch((error) => {
        toast.error(
          "Une erreur est survenue lors de la suppression: (status:" +
            error.response.status +
            ") => " +
            error.response.data
        );
      });
  };
  const loadUserData = () => {
    axiosInstance
      .get(`${endpoint}/users`)
      .then((result) => {
        if (result.data.length > 0) {
          const data = {
            columns: [],
            getRowId: (row) => row._id,
            rows: result.data,
          };
          for (let key in result.data[0]) {
            data.columns.push({
              field: key,
              headerName: (columnMap[key] && columnMap[key].headerName) || key,
              flex: (columnMap[key] && columnMap[key].flex) || 0.1,
            });
          }
          data.columns.push({
            field: "action",
            headerName: "Actions",
            width: 140,
            minWidth: 140,
            renderCell: (params) => {
              return (
                <>
                  <Link to={"/users/edit/" + params.row._id}>
                    <IconButton className="userListEdit">
                      <EditTwoTone fontSize="inherit" color="primary" />
                    </IconButton>
                  </Link>
                  <IconButton
                    className="userList"
                    data-id={params.row._id}
                    onClick={openDeleteDialog}
                  >
                    <DeleteTwoTone
                      fontSize="inherit"
                      sx={{ color: red[700] }}
                    />
                  </IconButton>
                </>
              );
            },
          });
          setUsers(result.data);
          setDataUsers(data);
        }
      })
      .catch((e) => {
        toast.error("Nous ne parvenons pas à retrouver la liste d'utilisateur");
        console.error("could not retrieve list of user ", e);
      });
  };

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <Paper>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item></Grid>
            <Grid item xs></Grid>
            <Grid item>
              <Button
                onClick={() => {
                  navigate("./add");
                }}
                variant="contained"
                sx={{ mr: 1 }}
              >
                Add user
              </Button>
              <Tooltip title="Reload">
                <IconButton onClick={loadUserData}>
                  <RefreshIcon sx={{ display: "block" }} fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {(users && users.length == 0 && (
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
          Aucun trouvé
        </Typography>
      )) ||
        (dataUsers && (
          <Grid item sx={{ height: 520, width: "100%" }}>
            <DataGrid
              sx={{ border: "none", width: "100%" }}
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              {...dataUsers}
              loading={users === undefined}
              rowHeight={38}
              disableSelectionOnClick
            />
          </Grid>
        ))}

      <DeleteDialog
        handleDelete={handleDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        msge={"Voulez-vous vraiment supprimer cet utilisateur?"}
      />
    </Paper>
  );
}
