import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axiosInstance from "../../services/AxiosService";
import { endpoint } from "../../utils/API";
import { toast } from "react-toastify";
import Paper from "@mui/material/Paper";
import { LinearProgress, Typography } from "@mui/material";
import {
  aggregateBogToFormComponent,
  aggregateFormComponentToBlog,
} from "../../aggregators/BlogAggregator";
import { UserContext } from "../../contexts/UserContext";

import * as Yup from "yup";
import { TypeContext } from "../../contexts/TypeContext";
import * as React from "react";
import { FromLocalStorageContext } from "../../contexts/FromLocalStorage";
import CMSFormComponents from "../../components/cms/Form/CMSFormComponents";

export default function AddEdit({ s3, type }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [forceCreationDate, setForceCreationDate] = useState(false);

  const isAddMode = !id;
  const [components, setComponents] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const { user } = useContext(UserContext);

  const [componentCount, setComponentCount] = useState({});
  const validationSchemaInit = Yup.object().shape({
    slug: Yup.string()
      .matches(
        /^[a-z0-9]+(?:-[a-z0-9\-\_]+)*$/g,
        "Le slug doit être composé de lettres minuscules et/ou chiffres et/ou des signes -_"
      )
      .required("requis"),
  });
  const [validationSchema, setValidationSchema] =
    useState(validationSchemaInit);

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    onSubmit,
  });
  let typeContext = {
    type,
    s3,
  };

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  const { setLoadedLocally } = useContext(FromLocalStorageContext);

  const loadData = () => {
    setLoading(true);

    let state = localStorage.getItem(isAddMode ? "addBlog" : "editBlog:" + id);
    state = state && JSON.parse(state);
    if (state) {
      setLoadedLocally(true);
      const { formikValues, components, componentCount } = state;
      if (formikValues && Object.keys(formikValues).length > 0) {
        setInitialValues((prevState) => {
          return { ...formikValues };
        });
      }
      setComponentCount((prevState) => {
        return { ...componentCount };
      });
      setComponents((prevState) => {
        return components;
      });
      setLoading(false);
    } else {
      setLoadedLocally(false);
      axiosInstance(`${endpoint}/components?type=${type}`).then((response) => {
        let componentsDefault = response.data;
        if (isAddMode) {
          componentsDefault = componentsDefault.map((component) => {
            componentCount[component.name] = 0;
            component.componentIndex = 0;
            component.position = 0;
            return component;
          });
          setComponentCount((prevState) => {
            return { ...componentCount };
          });
          setComponents((prevState) => {
            return componentsDefault;
          });
          setLoading(false);
        } else {
          axiosInstance(`${endpoint}/blog/${id}`).then((response) => {
            const blog = response.data;
            const blogForm = aggregateBogToFormComponent(blog);
            let active = {};
            for (let key in blogForm.components) {
              active[key] = true;
            }
            const componentMap = {};
            let disabledComponents = [];
            let componentsFromUpdate = [];
            componentsDefault.forEach((component) => {
              componentMap[component.name] = component;
            });

            let mapOfComponentActive = {};

            for (let i in blog.components) {
              const name = blog.components[i].name;
              let component = componentMap[name];
              mapOfComponentActive[name] = true;
              if (component) {
                let componentClone = JSON.parse(JSON.stringify(component));
                componentCount[name] =
                  (componentCount[name] !== undefined &&
                    componentCount[name] + 1) ||
                  1;
                componentClone.componentIndex =
                  (componentCount[name] && componentCount[name] - 1) || 0;
                componentClone.position = i;
                componentsFromUpdate.push(componentClone);
              }
            }
            for (let name in componentMap) {
              let component = componentMap[name];
              if (!mapOfComponentActive[name]) {
                if (component.isContainer) {
                  componentsFromUpdate.unshift(component);
                } else {
                  component.disabled = true;
                  disabledComponents.push(component);
                  componentCount[component.name] = 0;
                  component.componentIndex = 0;
                  component.position = 0;
                }
              }
            }
            componentsFromUpdate =
              componentsFromUpdate.concat(disabledComponents);
            setInitialValues((prevState) => {
              return blogForm;
            });
            setComponents((prevState) => {
              return componentsFromUpdate;
            });
            setComponentCount((prevState) => {
              return { ...componentCount };
            });
            setLoading(false);
          });
        }
      });
    }
  };
  useEffect(() => {
    loadData();
    // get user and set form fields
  }, [type]);

  function onSubmit(fields, { setStatus, setSubmitting }) {
    components.forEach((component, index) => {
      if (!component.isContainer && !component.disabled) {
        fields.components[component.name][component.componentIndex].position =
          index;
      }
    });
    const blog = aggregateFormComponentToBlog(fields);
    blog.author = user.name;
    blog.type = type;
    if (!forceCreationDate) {
      delete blog.created;
    }
    if (isAddMode) {
      localStorage.removeItem("addBlog");
      createBlog(blog, setSubmitting);
    } else {
      localStorage.removeItem("editBlog:" + id);
      updateBlog(blog, setSubmitting);
    }
  }

  function createBlog(blog, setSubmitting) {
    axiosInstance
      .post(`${endpoint}/blog`, blog)
      .then(() => {
        toast.success("Blog added");
        navigate("../");
      })
      .catch((error) => {
        toast.error(
          "Une erreur est survenue lors de la création: (status:" +
            error.response.status +
            ") => " +
            error.response.data
        );
        setSubmitting(false);
      });
  }

  function updateBlog(blog, setSubmitting) {
    axiosInstance
      .put(`${endpoint}/blog/${id}`, blog)
      .then(() => {
        toast.success("Blog mis à jour");
        navigate("../");
      })
      .catch((error) => {
        toast.error("Une erreur est survenue lors de la mise à jour");
        setSubmitting(false);
      });
  }

  return (
    <Paper>
      {(loading && (
        <>
          <Typography
            sx={{ my: 5, mx: 2 }}
            color="text.secondary"
            align="center"
          >
            Chargement des données
          </Typography>
          <LinearProgress />
        </>
      )) || (
        <TypeContext.Provider value={typeContext}>
          <CMSFormComponents
            {...{
              forceCreationDate,
              setForceCreationDate,
              setComponents,
              componentCount,
              setComponentCount,
              components,
              loadData,
              formik,
            }}
          />
        </TypeContext.Provider>
      )}
    </Paper>
  );
}
