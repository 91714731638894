import * as React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import ListIcon from "@mui/icons-material/List";
import EditIcon from "@mui/icons-material/Edit";

import axiosInstance from "../../services/AxiosService";
import { endpoint } from "../../utils/API";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { MenuBook } from "@mui/icons-material";
import { RoutesContext } from "../../contexts/RoutesContext";

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

const iconMap = {
  Group: <GroupIcon />,
  List: <ListIcon />,
  MenuBook: <MenuBook />,
  Edit: <EditIcon />,
};

function Sidebar(props) {
  const location = useLocation();
  const { ...other } = props;
  const [categories, setCategories] = React.useState();
  const { setRoutes } = useContext(RoutesContext);

  useEffect(() => {
    if (categories == undefined) {
      axiosInstance.get(`${endpoint}/sidebar`).then((result) => {
        let categoriesRes = result.data;
        setCategories(categoriesRes);
        let routesFromCat = [];
        categoriesRes.forEach((cat) => {
          cat.children.forEach((child) => {
            routesFromCat.push({
              type: child.id,
              s3: child.s3,
              route: child.route,
              path: child.path,
              component: child.component || "",
            });
          });
        });
        setRoutes((prevState) => {
          return routesFromCat;
        });
      });
    }
  }, []);

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
        >
          LIGHTSPEED
        </ListItem>
        <ListItem sx={{ ...item, ...itemCategory }}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>Menu</ListItemText>
        </ListItem>
        {(categories &&
          categories.map(({ id, children }) => (
            <Box key={id} sx={{ bgcolor: "#101F33" }}>
              <ListItem sx={{ py: 2, px: 3 }}>
                <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
              </ListItem>
              {children.map(({ id: childId, path, label, icon }) => (
                <ListItem disablePadding key={childId}>
                  <ListItemButton
                    component={Link}
                    selected={location.pathname === path}
                    sx={item}
                    to={path}
                  >
                    <ListItemIcon>{iconMap[icon]}</ListItemIcon>
                    <ListItemText>{label}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}

              <Divider sx={{ mt: 2 }} />
            </Box>
          ))) || (
          <Box sx={{ bgcolor: "#101F33" }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: "#fff" }}>Loading ...</ListItemText>
            </ListItem>
          </Box>
        )}
      </List>
    </Drawer>
  );
}

export default Sidebar;
