import { retrieveTreeValue } from "../../../utils/misc";
import CMSUploadOrLinkImage from "./CMSUploadOrLinkImage";
import { Box } from "@mui/material";
import { AddCircleTwoTone, RemoveCircleTwoTone } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { useState } from "react";

export default function CMSListOfValuesAndUploadImages({
  component,
  property,
  formik,
}) {
  const [countImages, setCountImages] = useState({});

  let name =
    (!component.isContainer &&
      "components." +
        component.name +
        "." +
        component.componentIndex +
        "." +
        property.name) ||
    "properties." + property.name;
  let val = retrieveTreeValue(name, formik.values);
  let index = countImages[name] || 1;
  if (val && val.array && val.array.length > 0 && !countImages[name]) {
    index = val.array.length;
    setCountImages((prevState) => {
      return {
        ...prevState,
        [name]: val.array.length,
      };
    });
  }

  let listOfImages = [];
  for (let i = 0; i < index; i++) {
    const cmsParams = { component, property, index: i, formik };
    listOfImages.push(
      <CMSUploadOrLinkImage
        key={`CMSUploadOrLinkImage${component.name}${component.componentIndex}${i}`}
        {...cmsParams}
      />
    );
  }
  const handleAdd = () => {
    setCountImages((prevState) => {
      return { ...prevState, [name]: index + 1 };
    });
  };

  const handleDelete = () => {
    if (val) {
      val.array.pop();
    }
    setCountImages((prevState) => {
      return { ...prevState, [name]: index - 1 };
    });
  };
  return (
    <Box key={`addAndRemove${component.name}${component.componentIndex}`}>
      {listOfImages}
      <AddCircleTwoTone
        key={`addAndRemove${component.name}${component.componentIndex}ADD`}
        color="primary"
        onClick={handleAdd}
      />
      {countImages[name] > 1 && (
        <RemoveCircleTwoTone
          key={`addAndRemove${component.name}${component.componentIndex}REMOVE`}
          sx={{ color: red[500] }}
          onClick={handleDelete}
        />
      )}
    </Box>
  );
}
