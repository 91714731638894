import { useState } from "react";
import dayjs from "dayjs";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export default function CMSForceCreationDateProperties({
  formik,
  forceCreationDate,
  setForceCreationDate,
}) {
  const name = "created.date";
  let value;
  if (formik.values.created && formik.values.created.date) {
    value = dayjs(formik.values.created.date);
  } else {
    value = dayjs();
  }
  const label = "Forcer une date de creation";
  return (
    <Box sx={{ m: 1 }} key={"createdDateBog"}>
      {" "}
      <FormControlLabel
        label="Forcer la date de création"
        control={
          <Checkbox
            checked={forceCreationDate}
            onChange={(e) => {
              setForceCreationDate(e.target.checked);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
      />
      {forceCreationDate && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            id={name}
            required={false}
            name={name}
            label={label}
            variant="standard"
            fullWidth
            value={value}
            slotProps={{
              textField: { variant: "standard", required: false },
            }}
            format="DD/MM/YYYY HH:mm"
            onChange={(newValue) => formik.setFieldValue(name, newValue)}
          />
        </LocalizationProvider>
      )}
    </Box>
  );
}
